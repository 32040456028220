<template>
  <div class="app-container">
    <CustomCard>
      <SubHead> 基本信息 </SubHead>
      <el-divider></el-divider>
      <el-form :model="goodsInfo" ref="formRef">
        <!-- {{ goodsInfo }}
        {{ goodsInfo.product_name }} -->
        <el-row>
          <GridItem xl="12" lg="12" sm="12">
            <el-form-item label="商品名称">{{ goodsInfo.product_name }}</el-form-item>
          </GridItem>
          <GridItem xl="12" lg="12" sm="12">
            <el-form-item label="售卖价格" prop="sale_price"  :rules="[{ required: true, message: '售卖价格不能为空', trigger: 'change' }]">
              <el-input-number
                style="width: 250px"
                v-model="goodsInfo.sale_price"
                :min="sale_price"
                :max="99999999"
                :step="goodsInfo.sale_price_range"
                :step-strictly="false"
                controls-position="right"
                :placeholder="`建议零售价${sale_price}元`"
                @blur="computedPrice"
              ></el-input-number>
              <span style="margin-left: 10px; color: rgb(158, 39, 20)">
                * 价格变动需为<span style="font-weight: 700"> {{ goodsInfo.sale_price_range }} </span>的倍数
              </span>
            </el-form-item>
          </GridItem>
          <GridItem xl="12" lg="12" sm="12" v-if="$store.getters.userInfo.business_mode == 2">
            <el-form-item :label="goodsInfo.product_quality + '(库存数量)='" prop="white_product_quality" required>
              <el-input-number
                v-model="goodsInfo.white_product_quality"
                :min="0"
                :max="99999999"
                :step="1"
                :step-strictly="true"
                :precision="0"
                controls-position="right"
              ></el-input-number>
              <span class="i-m-l-10">
                (后台修改库存)+<el-link type="primary">{{ goodsInfo.member_product_quality}}</el-link>(酒库库存)
              </span>
            </el-form-item>
          </GridItem>
          <GridItem xl="12" lg="12" sm="12" v-else>
            <el-form-item label="库存数量">
              {{ goodsInfo.member_product_quality}}
            </el-form-item>
          </GridItem>
        </el-row>
        <el-row class="el-row-appoint" v-if="userInfo.if_white_list==1||userInfo.if_yellow_list==1">
          <GridItem xl="12" lg="12" sm="12">
            <el-form-item label="是否指定用户可见" prop="has_appoint_see" :rules="[{ validator: (rule, value, callback)=>validateHasAppointSee(rule, value, callback,goodsInfo.appoint_see_user_id_list), trigger: 'change' }]">
              <el-switch v-model="goodsInfo.has_appoint_see" :active-value="1" :inactive-value="0" @change="appointSeeChange" />
              <el-button size="small" style="margin-left: 10px;"  :disabled="!goodsInfo.has_appoint_see" @click="addMember">添加会员</el-button>
            </el-form-item>
          </GridItem>
          <el-row v-for="(item,index) in goodsInfo.appoint_see_user_id_list" :key="index">
            <grid-item xl="24" lg="24" sm="24">
              <el-form-item :label="`会员${index+1}`" :prop="'appoint_see_user_id_list.'+index+'.value'" :rules="[{ required: true, message: `会员${index+1}不能为空`, trigger: 'blur' }]">
                <el-input v-model="item.value" class="user-list-handle" clearable/>
                <el-button style="margin-left: 10px;" @click="delMember(index)" :disabled="goodsInfo.appoint_see_user_id_list.length===1">删除</el-button>
              </el-form-item>
            </grid-item>
          </el-row>
        </el-row>
      </el-form>
      <template #bottomOperation>
        <el-button type="primary" @click="validateForm">提交</el-button>
        <el-button style="margin-left: 10px" @click="$router.go(-1)">返回</el-button>
      </template>
    </CustomCard>
    <CustomCard>
      <SubHead> 操作日志 </SubHead>
      <div class="table-box">
        <el-table :data="tableData" border :header-cell-style="{ textAlign: 'center', background: '#fafafa', color: '#444'}" :cell-style="{ textAlign: 'center' }">
          <el-table-column label="序号" v-slot="scope"> {{ scope.$index + 1 }} </el-table-column>
          <el-table-column label="操作" prop="operate_type_name"></el-table-column>
          <el-table-column label="操作内容" prop="operate_content"></el-table-column>
          <el-table-column label="操作时间" prop="create_time"></el-table-column>
        </el-table>
      </div>
      <template #bottomOperation>
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          v-model:current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          v-model:page-size="formData.page_size"
          layout="total, prev, pager, next, sizes"
          :total="total"
        />
      </template>
    </CustomCard>
  </div>
</template>
<script>
import {
  getSuggestedPrices,
  checkWineStockProductPriceSetting,
  editPopWineStockProduct,
  pageQueryWineStockProductLog
} from '@/api/jiushi'
  import { computed } from 'vue'
  import { useStore } from 'vuex'

export default {
  setup() {
  const store = useStore()
  const userInfo = computed(() => store.getters.userInfo)
  const validateHasAppointSee = (rule, value, callback, list) => {
    if (value === '') {
      callback(new Error('请选择是否指定用户可见'))
    } else {
      if (value && list.length === 0) {
        callback(new Error('请选添加会员'))
      }
      callback()
    }
  }
  return { userInfo, validateHasAppointSee }
  },
  data() {
    return {
      goodsInfo: {
        sale_price: undefined,
        sale_price_range: 0,
        has_appoint_see: 0,
        appoint_see_user_id_list: []
      },
      sale_price: 0,
      product_quality: Number(this.$route.query.white_product_quality) || 0,
      formData: {
        sku: this.$route.query.sku || '',
        page_index: 1,
        page_size: 10
      },
      tableData: [],
      currentPage: 1,
      total: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const { sale_price_range: salePriceRange, has_appoint_see: hasAppointSee, appoint_see_user_id_list: appointSeeUserIdList } = this.$route.query
      const sale_price_range = salePriceRange ? parseInt(salePriceRange) : 0
      const has_appoint_see = hasAppointSee ? parseInt(hasAppointSee) : 0
      const appoint_see_user_id_list = !appointSeeUserIdList ? [] : appointSeeUserIdList.length ? appointSeeUserIdList.map(item => ({ value: item })) : []
      this.goodsInfo = { ...this.$route.query, sale_price_range, has_appoint_see, appoint_see_user_id_list }
      this.getSuggestedPricesData()
      this.getList()
    },
    appointSeeChange(v) {
      if (v) {
        this.goodsInfo.appoint_see_user_id_list.push({ value: '' })
      } else {
        this.goodsInfo.appoint_see_user_id_list = []
      }
    },
    addMember() {
      this.goodsInfo.appoint_see_user_id_list.push({ value: '' })
      this.$refs.formRef.validateField('has_appoint_see')
    },
    delMember(index) {
      this.goodsInfo.appoint_see_user_id_list.splice(index, 1)
      this.$refs.formRef.validateField('has_appoint_see')
    },
    getSuggestedPricesData() {
      getSuggestedPrices({ sku: this.goodsInfo.sku }).then((res) => {
        const { suggested_prices, sale_price_range } = res
        this.sale_price = suggested_prices / 100
        this.goodsInfo.sale_price_range = sale_price_range / 100
      })
    },
    computedPrice() {
      const { sale_price, sale_price_range } = this.goodsInfo
      if (sale_price_range === 0) return
      let ratio = (sale_price - this.sale_price) / sale_price_range
      if (!Number.isInteger(ratio)) {
        ratio = Math.round(ratio)
        this.goodsInfo.sale_price = this.sale_price + ratio * sale_price_range
      }
    },
    validateForm() {
      this.$refs.formRef.validate((valid, fields) => {
        if (valid) {
          this.submitGoodsInfo()
        }
      })
    },
    async submitGoodsInfo() {
      let { sku, sale_price, white_product_quality, has_appoint_see: hasAppointSee, appoint_see_user_id_list: appointSeeUserIdList } = this.goodsInfo
      if (!sale_price) {
        this.$message.warning('售卖价格不能为空')
        return
      }
      sale_price = sale_price * 100
      const appoint_see_user_id_list = appointSeeUserIdList.map(item => item.value.trim())
      try {
        const has_appoint_see = this.$store.getters.userInfo.if_white_list === 1 || this.$store.getters.userInfo.if_yellow_list === 1 ? hasAppointSee : null
        const res = await checkWineStockProductPriceSetting({ sku, sale_price, has_appoint_see, appoint_see_user_id_list })
        const { result_code, price_change_reason } = res
        if (result_code === 1) {
          this.$message.error(price_change_reason)
          return
        }
        editPopWineStockProduct({ sku, sale_price, product_quality: white_product_quality, has_appoint_see, appoint_see_user_id_list }).then((res) => {
          this.$message.success('操作成功')
          this.sale_price = sale_price / 100
          this.product_quality = white_product_quality
          this.formData.page_index = 1
          // this.getList()
          this.$router.back()
        })
      } catch (error) {}
    },
    getList(page) {
      if (page) this.formData.page_index = this.currentPage = page
      pageQueryWineStockProductLog({ ...this.formData }).then((res) => {
        const { data_list, total_page } = res
        this.tableData = data_list || []
        this.total = total_page || 0
      })
    },
    sizeChange(size) {
      this.formData.page_index = 1
      this.formData.page_size = size
      this.getList()
    },
    currentChange(currentPage) {
      this.formData.page_index = currentPage
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-row-appoint{
    display: block;
  }
  .user-list-handle{
    width: 300px;
  }
</style>
